var FormData = (function () {
  // Format data
  var format = (datas, forceIdentifierOnly = false) => {
    var formatedDatas = [];

    for (var key in datas) {
      var data = datas[key];

      if (Array.isArray(data)) {
        formatedDatas[key] = data.map(d => {
          if (Object.keys(d).length > 1 && forceIdentifierOnly === false) {
            return d;
          }

          return d.id;
        });
      } else if (data?.id !== undefined) {
        formatedDatas[key] =
          Object.keys(data).length > 1 && forceIdentifierOnly === false
            ? data
            : data.id;
      } else {
        formatedDatas[key] = data;
      }
    }

    return formatedDatas;
  };

  return {
    format: format,
  };
})();

export default FormData;