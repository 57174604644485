/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import useActivity from '@crosslud/hooks/useActivity';
import {Loader} from '@crosslud/components';
import { CommonAPI } from '@crosslud/apis/CommonAPI';

const ScrollTree = React.forwardRef(({
  data,
  height,
  dataFromUrl,
  searchFromUrl = "",
  renderItem,
  nextNodeSelector = 'children',
}, ref) => {
  const activityHook = useActivity();

  const [items, setItems] = useState([]);

  // New
  const callApi = async () => {
    if (dataFromUrl?.path) {
      var activityRef = activityHook.start();

      var callParams = {
        path: dataFromUrl.path,
        searchBy: dataFromUrl.searchBy,
        searchValue: searchFromUrl,
        page: 1
      }

      const response = await CommonAPI.callApi(callParams)

      if (response.status) {
        setItems(response?.payload);
      }

      activityHook.stop(activityRef);
    }
  };

  // EndPoint
  useEffect(() => {
    !data && dataFromUrl?.path ? callApi() : data && setItems(data);
  }, [])

  useEffect(() => {
    data && setItems(data);
  }, [data])

  return (
    <div className="container">
      <div className="row justify-content-center">
        {dataFromUrl?.path && activityHook.isLoading ? (
          <div className="col-12 tx-center">
            <Loader centerScreen={false} />
          </div>
        ) : (
          <div className="col-12 pd-y-20 bg-dark tx-white">
            <div className="categories-wrapper tx-white">
              <div style={height && {height: height, overflowY: "auto"}}>
                {items &&
                  <TreeRow
                    nextNode={dataFromUrl?.optionChildren ?? nextNodeSelector}
                    items={items}
                    renderItem={renderItem}
                  />
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
})

const TreeRow = ({nextNode = 'children', items, renderItem}) => {
  return (
    items?.length > 0 && (
      <ul>
        {items.map((item, index) => {
          return (
            <li key={index}>        
              <div>
                <span />
                {renderItem({item, index})}
              </div>
              {item?.[nextNode] && 
                <TreeRow 
                  nextNode={nextNode}
                  items={item?.[nextNode]}
                  renderItem={renderItem}
                />
              }
            </li>
          )
        })}
      </ul>
    )
  )
}

export default ScrollTree;