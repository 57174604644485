import React from 'react';
import { useNavigate } from 'react-router-dom';
import Text from '@crosslud/helpers/Text';
import { ScrollTree } from '@crosslud/components';

const CategoryTreeLink = ({data, to}) => {
  const navigate = useNavigate();

  const renderItem = ({item}) => (
    <label className="cursor-pointer">
      <input
        type="radio"
        onClick={() => {
          navigate(Text.sprintf(to, item))
        }}
      />
      <span className="mg-l-5">{item['translatedName']}</span>
    </label>
  )

  return (
    <ScrollTree
      data={data}
      idSelector={'id'}
      labelSelector={'translatedName'}
      renderItem={renderItem}
    />
  )
}

export default CategoryTreeLink;