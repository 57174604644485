/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import { CategoryBreadcrumbText, Modal, ScrollTree } from '@crosslud/components';
import Utils from '@crosslud/helpers/Utils';

const FormInputTree = React.forwardRef(({
  name,
  data,
  height,
  idSelector,
  labelSelector,
  dataFromUrl,
  nextNodeSelector = 'children',
  value,
  onChange,
  onBlur,
  renderItem
}, ref) => {
  const [initialized, setInitialized] = useState(false);
  const [selections, setSelections] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const optionId = dataFromUrl?.option?.value ?? idSelector;
  const optionLabel = dataFromUrl?.option?.label ?? labelSelector;
  const optionChildren = dataFromUrl?.option?.children ?? nextNodeSelector;

  // Set default tag
  const setDefaultValue = () => {
    var values = value;
    
    if (!Utils.isArray(values)) {
      values = [value];
    }

    if (values?.[0]?.[optionId] !== selections?.[0]?.[optionId]) {
      setSelections([values?.[0]]);
    }

    setInitialized(true);
  };

  // On change save selected
  const removeTag = () => {
    setSelections(null);
  }

  // On change save selected
  const handleFormChange = (selectedItem) => {
    setSelections([selectedItem]);
  }

  // Fire callback
  const handleFormSubmit = () => {
    setModalVisible(false);
    onChange && onChange(selections?.[0]);
  }

  const renderItemFormat = ({item}) => (
    renderItem ? renderItem({item}) :
    <label className="cursor-pointer">
      <input
        onBlur={onBlur}
        type="radio"
        name={name}
        onClick={() => handleFormChange(item)}
        defaultChecked={parseInt(selections?.[0]?.[optionId]) === parseInt(item?.[optionId])}
      />
      {optionLabel && <span className="mg-l-5">{item[optionLabel]}</span>}
    </label>
  )

  // EndPoint
  useEffect(() => {
    initialized === false && value && setDefaultValue();
  }, [value, initialized])

  // EndPoint
  useEffect(() => {
    selections !== undefined && handleFormSubmit();
  }, [selections])

  return (
    <div>
      <ul className="form-control form-control-dark" style={{padding: 0, margin: 0}}>
        {selections?.[0] && (
          <span className="btn btn-outline-warning mg-r-3" onClick={() => removeTag()}>
            {selections?.[0]?.alias ? (
              /* specific to categories api */
              <CategoryBreadcrumbText category={selections?.[0]} />
            ) : selections?.[0]?.[optionLabel]}
            <span className="remove">&nbsp;x</span>
          </span>
        )}
        <li className="btn btn-outline-success pd-x-20" onClick={() => setModalVisible(true)}>+</li>
      </ul>
      <Modal show={modalVisible} handleClose={() => setModalVisible(false)}>
        <ScrollTree
          data={data}
          height={height}
          dataFromUrl={dataFromUrl}
          nextNode={optionChildren}
          renderItem={renderItemFormat}
        />
      </Modal>
    </div>
  )
})

export default FormInputTree;